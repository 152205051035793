var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"section padding20 d-flex flex-column"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"flex-1",attrs:{"element-loading-background":"rgba(0, 0, 0, 0.5)"}},[(_vm.activityList.length)?_c('el-timeline',{staticClass:"timeline-account"},_vm._l((_vm.activityList),function(item,i){return _c('el-timeline-item',{key:i,attrs:{"timestamp":item.created_at,"placement":"top"}},[_c('div',{staticClass:"box flex align-center"},[(
                item.type == 1 ||
                item.type == 2 ||
                item.type == 7 ||
                item.type == 8 || item.type == 60
              )?_c('img',{staticStyle:{"width":"35px","height":"35px"},attrs:{"src":require("../assets/account/mdslogo.png")}}):(
                item.type == 14 ||
                item.type == 15 ||
                item.type == 16 ||
                item.type == 17
              )?_c('img',{staticStyle:{"width":"35px","height":"35px"},attrs:{"src":require("../assets/account/brclogo.png")}}):(
                item.type == 50 ||
                item.type == 51 ||
                item.type == 52 ||
                item.type == 53 ||
                item.type == 54 ||
                item.type == 55
              )?_c('img',{staticStyle:{"width":"35px","height":"35px","border-radius":"10px"},attrs:{"src":require("../assets/login/logo.webp")}}):(item.type == 3)?_c('img',{attrs:{"src":require("../assets/card/tianshi.png"),"width":"35px"}}):(item.type == 4)?_c('img',{attrs:{"src":require("../assets/card/shouzu.png"),"width":"35px"}}):_c('img',{attrs:{"src":require("../assets/summon/sum_icon.png"),"width":"35px"}}),_c('div',{staticClass:"padding-row10"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('activity.tip.' + item.type, {...item}))}}),_c('a',{attrs:{"href":_vm.process+'/tx/' + item.txhash,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(item.txhash))])])])])}),1):(!_vm.loading)?_c('div',{staticClass:"d-flex ai-center jc-center",staticStyle:{"min-height":"500px"}},[_c('img',{attrs:{"alt":"norecord","src":require("../assets/public/norecord.png")}})]):_vm._e(),(_vm.meta.last - _vm.meta.current > 0)?_c('p',{staticClass:"more"},[_c('span',{staticClass:"c-m hover",on:{"click":function($event){return _vm.pageFun(_vm.meta.current + 1)}}},[_vm._v(_vm._s(_vm.$t("activity.more"))+" >>")])]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }